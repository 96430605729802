<template>
  <div class="auth">
    <div class="login-logo">
      <router-link
          to="/"
          tag="img"
          :src="require(`@/assets/images/logo/logo-black.png`)"/>
    </div>
    <!-- auth section-->
    <section class="auth-section">
      <p>SIGN IN</p>
      <button class="sns-sign-in">SIGN IN WITH TWITTER</button>
      <button class="sns-sign-in">SIGN IN WITH FACEBOOK</button>
      <div class="auth-input">
        <form @submit.prevent="login">
          <label for="email">Email</label>
          <input type="email" id="email" placeholder="" v-model="email" name="email" value>
          <label for="password">Password</label>
          <label for="password">Password</label>
          <div class="input-password">
            <input type="text" id="password" ref="password" placeholder="" v-model="password" name="password" value>
            <span @click="changeInputType"><v-icon>{{ eyeIcon }}</v-icon></span>
          </div>
          <button type="submit" name="button">SIGN IN</button>
        </form>
        <a @click="goPage('reset-password')">Forgot your password?</a>
        <p>Don't have an account? <a @click="goPage('register')">Sign Up</a></p>
      </div>
    </section>
  </div>
</template>

<script>
import { POSITION } from "vue-toastification";

export default {
  name: 'Auth',
  components: {},
  data() {
    return {
      eyeIcon: 'mdi-eye',
      email: "",
      password: "",
      error: null,
    }
  },
  methods: {
    changeInputType() {
      if (this.$refs.password.type === 'text') {
        this.$refs.password.type = 'password'
        this.eyeIcon = 'mdi-eye-off'
      } else {
        this.$refs.password.type = 'text'
        this.eyeIcon = 'mdi-eye'
      }
    },
    goPage(name) {
      this.$router.push({name: name})
    },
    login() {
      this.$store
          .dispatch('app/login', {
            email: this.email,
            password: this.password
          })
          .then(() => {
            this.$router.push({name: 'dashboard'})
          })
          .catch(err => {
            this.error = err.response.data.error
            this.makeToast()
          });
    },
    makeToast() {
      this.$toast(this.error, {
        position: POSITION.BOTTOM_CENTER,
        timeout: 2000,
        toastClassName: "error-toast-class",
        bodyClassName: "error-class",
      })
    },
  }
}
</script>